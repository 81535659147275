import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import slide1 from "../../images/ultimateService.png"
// import slide2 from "../../images/brainStorming1.png"
// import slide3 from "../../images/webDevelopment1.png"



export default function ImageSlider() {
    return (
      <div className="abstractLogosWrapper">
        <div className="abstractLogosExamples">
          <Container>
            <Carousel>
              <Carousel.Item>
                <img className="d-block w-100" src={slide1} alt="all services display" />
              </Carousel.Item>
            </Carousel>
          </Container>
        </div>
      </div>
    )
}
