import React from "react"
import { graphql} from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageSlider from "../components/Home/ImageSlider"
import HeadingInfo from "../components/Home/HeadingInfo"
import Services from "../components/Home/Services"
import HowItWorks from "../components/Home/HowItWorks"
import Pricing from "../components/Home/Pricing"

const IndexPage = ({ data }) => (
  <Layout>
    <HeadingInfo />
    <SEO title="Orlando Florida logo, Web development, and digital marketing agency" keywords={["Web Development Kissimmee", "Logo Design Kissimmee", "Digital Marketing Kissimmee"]} />
    <ImageSlider />
    <Services />
    <HowItWorks />
    <Pricing />
  </Layout>
)


export const query = graphql`
         {
           img: file(relativePath: { eq: "webDevelopment1.png" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_tracedSVG
               }
             }
           }
           services: allContentfulConsultationItem {
             edges {
               node {
                 id
                 title
                 description {
                   description
                 }
                 price
                 category
                 image {
                   description
                   fluid(maxHeight: 100) {
                     ...GatsbyContentfulFluid_tracedSVG
                   }
                 }
               }
             }
           }
         }
       `

export default IndexPage


//  <BackgroundSection img= {data.img.childImageSharp.fluid} 
//     title='Web development services in Orlando' 
//     styleClass='default-background'
//     />