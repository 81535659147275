import React from "react"
import { Jumbotron, Button, Card, Container } from "react-bootstrap"

export default function HeadingInfo() {
  return (
    <div className="headingInfo">
      <Container fluid>
        <Card className="localSupportHeader">
          <Card.Body>
            Promote local business! <strong> 10%</strong> of sales will go to
            local nonprofit to support the growth of our local community.
          </Card.Body>
        </Card>
        <Container>
          <Jumbotron className="HeadingInfoJumbotron">
            <h1 className="MainHeadingJumbotron">
              Orlando Florida
              <br /> Logo,
              <br /> Web Design,
              <br /> Graphic Design,
              <br /> and more
            </h1>
            <p className="headingInfoText">
              Welcome to LOGOPWA! We are an online{" "}
              <strong>
                graphic design, web programming, and app development agency
              </strong>{" "}
              specializes in custom logo design, web application, web design
              through the use of{" "}
              <strong>Programming Web Application(PWA) technologies</strong>, and
              other unique tools that make our clients business ideas a reality.
            </p>
            <div className="wrapper">
              <Button
                variant="primary"
                className="ReservationButton"
                href="https://calendly.com/logopwa"
              >
                Let's Reserve a <strong> Free Consultation</strong> Now
              </Button>
            </div>
          </Jumbotron>
        </Container>
      </Container>
    </div>
  )
}
