import React from "react"
import { Carousel, Container } from "react-bootstrap"
import getConsultation from "../../images/getConsultationNow.png"
import buyService2 from "../../images/buyService2.png"
import concepts from "../../images/concepts.png"
// import Title from "../Globals/Title"

export default function HowItWorks() {
  return (
    <div className="howItWorksWrapper">
      <div className="howItWorksSection">
        <Container>
          <h1 className="howItWorksTitle"> How it Works</h1>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={getConsultation}
                alt="Get consultation"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={buyService2}
                alt="Buy service"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={concepts}
                alt="Get concepts"
              />
            </Carousel.Item>
          </Carousel>
        </Container>
      </div>
    </div>
  )
}

// <div>
//     <Card className="bg-dark text-white">
//       <Card.Img src={consultation} alt="Card image" />
//       <Card.ImgOverlay>
//         <Card.Title>Card title</Card.Title>
//         <Card.Text>
//           This is a wider card with supporting text below as a natural
//           lead-in to additional content. This content is a little bit
//           longer.
//         </Card.Text>
//         <Card.Text>Last updated 3 mins ago</Card.Text>
//       </Card.ImgOverlay>
//     </Card>
//   </div>
